import React from 'react';
import '../../styles/footer/footer.scss';

const Footer = () => {
    return(
        <section id='footer-layout'> 
            <footer id='footer'>
                <p id='left' className='footer-text'>Intern.house is not affiliated with the companies employing its tenants.</p>
                <p id='right' className='footer-text'>© 2022-2025, Intern.House</p>
            </footer>
        </section>
    );
}


export default Footer;