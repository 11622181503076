import React from 'react';
import '../../styles/about/aboutUs.scss';

const brian = require('../../assets/about/brianHeadshot.webp');
const justin = require('../../assets/about/justinHeadshot.webp');
const murtaza = require('../../assets/about/murtazaHeadshot.webp');

const AboutUs = () => {
    return (
        <div id='about-us-layout'>
            <h1 id='about-us-title'>About Us</h1>
            <section id='contact-section'>
                <section id='brian' className='about-us-name-sections'>
                    <img id='brian-headshot' alt='' className='headshot' src={brian}></img>
                    <h1 id='brian-name' className='owner-name'>Brian Coombs</h1>
                    <a className='owner-email' href='mailto:brian@dothouse.group'>brian@intern.house</a>
                </section>
                {/* <section id='justin' className='about-us-name-sections'>
                    <img id='justin-headshot' alt='' className='headshot' src={justin}></img>
                    <h1 id='justin-name' className='owner-name'>Justin Goodwin</h1>
                    <a className='owner-email' href='mailto:justin@intern.house'>justin@intern.house</a>
                </section> */}
                <section id='murtaza' className='about-us-name-sections'>
                    <img id='murtaza-headshot' alt='' className='headshot' src={murtaza}></img>
                    <h1 id='murtaza-name' className='owner-name'>Murtaza Hakimi</h1>
                    <a className='owner-email' href='mailto:murtaza@dothouse.group'>murtaza@intern.house</a>
                </section>
            </section>
            <p id='about-us-text'>We’re an Austin-based startup founded by ex-interns and Austin natives. We know how it feels to move to a new city full of strangers and founded Intern.House to make that experience more friendly and straight-forward. <br /> <br />Our houses provide a way to build an immediate friend group at your company while not having to worry about the usual intern housing headaches of finding furnishings, unknown roommates, or complicated leases. <br /> <br />We hope you enjoy your stay at your Intern House. Feel free to email us at <a className='email-link' href='mailto:brian@dothouse.group'>brian@dothouse.group</a> if you have any questions!</p>
        </div>
    );
}


export default AboutUs;