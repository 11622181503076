import React, { Component } from 'react';
import Select from 'react-select';
import { companyOptions } from './Options';

class Selector extends Component {
    render() {
        const { selectedOption, handleChange } = this.props;

        const customStyles = {
            control: (base, state) => ({
                ...base,
                margin: "0",
                "marginBottom": "8%",
            }),
            input: (base, state) => ({
                ...base,
                color: "#2d3142",
                "fontFamily": "Roboto",
                "fontSize": "2.0rem",
            }),
            option: (base, state) => ({
                ...base,
                "fontFamily": "Roboto",
                "fontSize": "1.4rem",
            }),
            singleValue: (base, state) => ({
                ...base,
                "fontFamily": "Roboto",
                "fontSize": "2.0rem",
            }),
        }

        return (
            <Select
                styles={customStyles}
                value={selectedOption}
                onChange={handleChange}
                options={companyOptions}
            />
        );
    }
}

export default Selector;