import React from 'react';
import '../../styles/carousel/carousel.scss';
import {
    ButtonBack, ButtonNext,
    CarouselProvider, Image, Slide, Slider
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ArrowForward } from '@mui/icons-material';
import { ArrowBack } from '@mui/icons-material';


function importAll(r) {
    return r.keys().map((fileName, index) => ({
        url: r(fileName), 
        fileName: fileName.substring(2) // remove './' from the start of the filename
    }));
}
  
const carouselImages = importAll(require.context('../../assets/carousel', false, /\.(png|jpe?g|svg|webp)$/));
console.log(carouselImages)
  

const Carousel = (props) => {

        return (
            <CarouselProvider
                id='carousel'
                totalSlides={props.length}
                infinite
                visibleSlides={1}
                hasMasterSpinner
                naturalSlideWidth={100}
                naturalSlideHeight={60}
                dragEnabled={false}
                touchEnabled={false}
                currentSlide={props.index}
                style={{ maxWidth:'50%'}}
            >
                <div id='sliders' style={{ position: 'relative' }}>
                    <Slider>
                        {props.houses.map((house, index) => (
                            <Slide key={house.houseName} index={index}>
                                <Image src={(carouselImages.find(img => img.fileName === house.imageFileName)).url} alt={house.houseName} tag='div' className='carousel-image' isBgImage >
                                    <div className='tag-layout'>
                                        <p className='avail-tags' style= {{ paddingRight:"2%", marginTop:'10px' }}>{house.availabilityText}<br />{house.priceRangeText}</p>
                                    </div>
                                </Image>
                            </Slide>
                        ))}
                    </Slider>
                    {/* If only 1 house in the current filter, removes the back and next buttons on the carousel */}
                    {props.length !== 1 && 
                    <div>
                        <ButtonBack onClick={props.handlePrevious} id='button-back' className='arrows'><ArrowBack /></ButtonBack>
                        <ButtonNext onClick={props.handleNext} id='button-next' className='arrows'><ArrowForward /></ButtonNext>
                    </div>
                    }
                </div>
            </CarouselProvider>
        );
    }


export default Carousel;
