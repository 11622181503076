import React, { useState, useEffect } from 'react';
import '../../styles/carousel/carousel.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Carousel from './Carousel';
import Selector from './Selector';
import { companyOptions } from './Options';

const CarouselSection = () => {
    const location = useLocation();

    const [index, setIndex] = useState(0);
    const [houses, setHouses] = useState([
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Tai Londo',
        //     imageFileName: 'taiLondo.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '5 bedrooms, 3 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/13500-tai-londo-drive-austin-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         '2 Living rooms, smart TVs & PS5',
        //         '5 minutes from Tesla',
        //         'Huge master bedroom'
        //     ],
        //     property_name: '13500 Tai Londo Drive'
        // },
        // {
        //     companies: ["tesla", "samsung"],
        //     houseName: 'John Gill',
        //     imageFileName: 'johnGill.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '7 beds, 3 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/20603-john-gill-dr-manor-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new modern house',
        //         'Living room with smart TVs',
        //         '10 mins from Tesla and Samsung',
        //         'Large master bedroom'
        //     ],
        //     property_name: '20603 John Gill Drive'
        // },
        {
            companies: ["tesla", "spacex"],
            houseName: 'Brocade A',
            imageFileName: 'brocade.webp',
            availabilityText: "4 room(s) available in August",
            priceRangeText: "$950 - $1200",
            bedBath: '5 bedrooms, 3 baths',
            tourOrPictures: 'tour',
            tourLink: 'https://www.virtuance.com/listing/5904-brocade-drive-austin-texas',
            applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
            info: [
                'Brand new house',
                '2 living rooms with smart TVs',
                '5 minutes from Tesla',
                'Smart home lights and speakers'
            ],
            property_name: '5904 Brocade Drive'
        },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Brocade',
        //     imageFileName: 'brocadeB.webp',
        //     availabilityText: "2 room(s) available in May",
        //     priceRangeText: "$950 - $1200 / month",
        //     bedBath: '5 beds, 3 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/5908-brocade-dr-austin-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         '2 living rooms with smart TVs',
        //         '5 minutes from Tesla',
        //         'Spacious with large master bedroom'
        //     ],
        //     property_name: '5908 Brocade Drive'
        // },
        {
            companies: ["tesla", "spacex"],
            houseName: 'Cottingham',
            imageFileName: 'cottingham.webp',
            availabilityText: "4 room(s) available in August",
            priceRangeText: "$850 - $1100",
            bedBath: '5 bedrooms, 2.5 baths',
            tourOrPictures: 'tour',
            tourLink: 'https://www.virtuance.com/listing/14302-cottingham-drive-austin-texas',
            applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
            info: [
                'Oculus VR and PS5',
                '2 living rooms with smart TVs',
                '5 minutes from Tesla',
                'Garage gym and backyard grill'
            ],
            property_name: '14302 Cottingham Drive'
        },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Sabal Palm',
        //     imageFileName: 'sabal.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '4 beds, 2.5 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/15104-sabal-palm-road-austin-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Huge backyard',
        //         'Living room with smart TV',
        //         '7 minutes from Tesla',
        //         'Large master bedroom'
        //     ],
        //     property_name: '15104 Sabal Palm Road'
        // },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Siberian Elm',
        //     imageFileName: 'siberianElm.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '4 beds, 2 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/15100-siberian-elm-austin-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         'Living room with smart TV',
        //         '7 minutes from Tesla',
        //         'Huge master bedroom'
        //     ],
        //     property_name: '15100 Siberian Elm Lane'
        // },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Pale Glow Cove A',
        //     imageFileName: 'paleGlowA.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '4 beds, 2.5 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/15300-pale-glow-cv-del-valle-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         'Living room with smart TV',
        //         '10 minutes from Tesla',
        //         'Spacious backyard'
        //     ],
        //     property_name: '15300 Pale Glow Cove'
        // },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Pale Glow Cove B',
        //     imageFileName: 'paleGlowB.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '4 beds, 2 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/15308-pale-glow-cv-del-valle-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         'Living room with smart TV',
        //         '10 minutes from Tesla',
        //         'Spacious backyard'
        //     ],
        //     property_name: '15308 Pale Glow Cove'
        // },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Ivy Trellis',
        //     imageFileName: 'ivyTrellis.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '4 beds, 2 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/7611-ivy-trellis-trl-del-valle-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         'Living room with smart TV',
        //         '10 minutes from Tesla',
        //         'Large master bedroom'
        //     ],
        //     property_name: '7611 Ivy Trellis Trail'
        // },
        // {
        //     companies: ["tesla", "spacex"],
        //     houseName: 'Cottage Orchid',
        //     imageFileName: 'cottageOrchid.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '5 beds, 2.5 baths',
        //     tourOrPictures: 'tour',
        //     tourLink: 'https://www.virtuance.com/listing/15512-cottage-orchid-drive-del-valle-texas',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Brand new house',
        //         '2 living rooms with smart TVs',
        //         '10 minutes from Tesla',
        //         'Large master bedroom'
        //     ],
        //     property_name: '15512 Cottage Orchid Drive'
        // },
        {
            companies: ["amd", "google", "cirrus logic"],
            houseName: 'Salcon Cliff Drive',
            imageFileName: 'salconCliff.webp',
            availabilityText: "4 room(s) available in August",
            priceRangeText: "$1050 - $1400",
            bedBath: '5 beds, 4 baths',
            tourOrPictures: 'tour',
            tourLink: 'https://www.virtuance.com/listing/6206-salcon-cliff-drive-austin-texas',
            applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
            info: [
                '3300 square feet',
                '7 min from AMD, 12 from Google, 17 from Cirrus',
                'Solar panels for reduced utilities',
                'Walking distance to groceries and restaurants'
            ],
            property_name: '6206 Salcon Cliff Dr'
        },
        // {
        //     companies: ["tesla", "samsung", "spacex"],
        //     houseName: 'Chappie Lane',
        //     imageFileName: 'chappieLane.webp',
        //     availabilityText: "Loading Availability...",
        //     priceRangeText: "",
        //     bedBath: '4 beds, 2 baths',
        //     tourOrPictures: 'pictures',
        //     tourLink: 'https://drive.google.com/drive/folders/1G8yT-q5C1xOX2gGcjHSDywja1VYWAbuR?usp=sharing',
        //     applyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd7moqxKKxk7mP0K_rex3Giv5ngFi4f8qQ8rf81urZ-wY1U3A/viewform?usp=sf_link',
        //     info: [
        //         'Fully furnished',
        //         'Living room with smart TV',
        //         '10 minutes from Tesla',
        //         'Large master bedroom'
        //     ],
        //     property_name: '3704 Chappie Lane'
        // }
      ]);
    

    //For selector
    const [selectedOption, setSelectedOption] = useState(companyOptions[0]);
    let filteredHouses = houses.filter(house => house.companies.includes(selectedOption.value));
    let length = Object.keys(filteredHouses).length;

    console.log(filteredHouses);

    const findIndexByLabel = (label) => {
        const index = companyOptions.findIndex((item) => item.label.toLowerCase() === label.toLowerCase());
        return index;
    };

    const handleChange = (selectedOption) => {
        //BUG HERE - the set index to 0 state function doesn't execute in time so the index is still 3 when switching from Tesla to Google
        //To recreate, click to the 3rd slide of the Tesla carousel then switch companies to Google. Your section text will be mismatched to the picture.
        setIndex(0);
        console.log(index);
        setSelectedOption(selectedOption);
        filteredHouses = houses.filter(house => house.companies.includes(selectedOption));
        length = Object.keys(filteredHouses).length;
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlValue = searchParams.get('company');

        const handleApplyButtonClick = () => {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click_apply', {
                    transaction_id: "T" + Math.floor(Math.random() * 1000001),
                    house_name: filteredHouses[index].houseName
              });
            }
          };
      
        const handle3DTourButtonClick = () => {
            if (typeof window.gtag === 'function') {
                    window.gtag('event', 'click_3d_tour', {
                        transaction_id: "T" + Math.floor(Math.random() * 1000001),
                        house_name: filteredHouses[index].houseName
                });
            }
        };
      
        const applyButton = document.getElementById('apply-button');
        const tourButton = document.getElementById('3d-tour-button');
    
        if (applyButton) {
            applyButton.addEventListener('click', handleApplyButtonClick);
        }
        if (tourButton) {
            tourButton.addEventListener('click', handle3DTourButtonClick);
        }
      

        if (urlValue) {
            let idx = findIndexByLabel(urlValue)
            if (idx === -1) {
                handleChange(companyOptions[0])
            }
            else {
                console.log(companyOptions[idx])
                handleChange(companyOptions[idx])
            }
        }
    // Construct the dynamic URL based on the current domain without the current port
    const table_name = 'property_availability'
    const apiUrl = `https://us-central1-dot-house-automation.cloudfunctions.net/get_table_data?table=${table_name}`;
    // Fetch availability data from the Express server
    // fetch(apiUrl)
    //   .then(response => response.json())
    //   .then(data => {
    //     const availabilityData = data;

    //     // Process the availability data
    //     const housesMap = {};

    //     availabilityData.forEach(item => {
    //       if (!housesMap[item.property_name]) {
    //         housesMap[item.property_name] = {
    //           name: item.property_name,
    //           units: []
    //         };
    //       }
    //       housesMap[item.property_name].units.push({
    //         unit_id: item.unit_id,
    //         unit_name: item.unit_name,
    //         market_rent: parseFloat(item.market_rent),  // Ensure market_rent is treated as a number
    //         earliest_vacancy: item.earliest_vacancy
    //       });
    //     });

    //     const updatedHouses = Object.keys(housesMap).map(property_name => {
    //       const house = housesMap[property_name];
    //       const unitsWithDates = house.units.filter(unit => unit.earliest_vacancy !== 'AtWill');

    //       const earliestMonth = unitsWithDates.reduce((earliest, unit) => {
    //         const vacancyDate = new Date(unit.earliest_vacancy);
    //         if (!earliest || vacancyDate < earliest) {
    //           return vacancyDate;
    //         }
    //         return earliest;
    //       }, null);

    //       const countUnitsInEarliestMonth = unitsWithDates.filter(unit => {
    //         const vacancyDate = new Date(unit.earliest_vacancy);
    //         return (
    //           vacancyDate.getMonth() === earliestMonth.getMonth() &&
    //           vacancyDate.getFullYear() === earliestMonth.getFullYear()
    //         );
    //       }).length;

    //       const marketRents = house.units.map(unit => unit.market_rent);
    //       const minRent = Math.min(...marketRents);
    //       const maxRent = Math.max(...marketRents);

    //       return {
    //         name: house.name,
    //         availabilityText: `${countUnitsInEarliestMonth} room(s) available in ${earliestMonth ? earliestMonth.toLocaleString('default', { month: 'long' }) : 'N/A'}`,
    //         marketRentRange: `$${minRent} - $${maxRent} /month`
    //       };
    //     });

    //     // Create a copy of the houses array to avoid direct state mutation
    //     const newHouses = houses.map(house => {
    //       const updatedHouse = updatedHouses.find(u => u.name === house.property_name);
    //       if (updatedHouse) {
    //         return {
    //           ...house,
    //           availabilityText: updatedHouse.availabilityText,
    //           priceRangeText: updatedHouse.marketRentRange
    //         };
    //       }
    //       return house;
    //     });

    //     setHouses(newHouses);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching availability data:', error);
    //   });
    }, []);

    const handlePrevious = () => {
        const newIndex = index - 1;
        setIndex(newIndex < 0 ? length - 1 : newIndex);
        console.log(index);
    };

    const handleNext = () => {
        const newIndex = index + 1;
        setIndex(newIndex >= length ? 0 : newIndex);
        console.log(index);
    };

    return (
        <div id='carousel-layout'>
            <div id='carousel-title-and-selector'>
                <h1 style={{ marginRight: '10px' }} id='carousel-title'>{length} Intern House{length === 1 ? '':'s'} in Austin, TX near </h1>
                <Selector selectedOption={selectedOption} handleChange={handleChange}/>
            </div>
            
            <Carousel houses={filteredHouses} index={index} length={length} handlePrevious={handlePrevious} handleNext={handleNext}/>

            <section id='carousel-info-section'>
                <section id='carousel-title-section'>
                    <h1 id='carousel-house-title'>{filteredHouses[index].houseName}</h1>
                    <h2 id='carousel-bed-bath'>{filteredHouses[index].bedBath}</h2>
                </section>
                <ul id='info-list'>
                    {filteredHouses[index].info.map((el, key = -1) => {
                        key += 1;
                        return <li key={key} className='list-items'>{el}</li>
                    })}
                </ul>
            </section>
            <section id='carousel-button-section'>
                { filteredHouses[index].tourLink !== '' &&
                    <motion.a whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    whileInView={{ opacity: 1 }} id='3d-tour-button' className='carousel-button' href={filteredHouses[index].tourLink} target='_blank'>{filteredHouses[index].tourOrPictures=='pictures'?"Pictures":"3D Tour"}</motion.a>
                }
                
                <motion.a whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    whileInView={{ opacity: 1 }} id='apply-button' className='carousel-button' href={filteredHouses[index].applyLink} target='_blank'>Apply</motion.a>
            </section>
        </div>
    );
}

export default CarouselSection;
